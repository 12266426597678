export const S3BucketJobseeker = process.env.S3_BUCKET_URL

// Logo
export const BossjobLogo = `${S3BucketJobseeker}/Bossjob.svg`
export const BossjobFittedLogo = `${S3BucketJobseeker}/BossjobFittedLogo.svg`
export const BossjobFittedLogoApp = `${S3BucketJobseeker}/bossjob-fittedlogo-app.png`
export const BossjobLogoWhite = `${S3BucketJobseeker}/BossjobLogoWhite.svg`
export const Offerbottom = `${S3BucketJobseeker}/Offerbottom.svg`
export const Offercenter = `${S3BucketJobseeker}/Offercenter.svg`
export const Offermask = `${S3BucketJobseeker}/Offermask.svg`
export const Offerhead = `${S3BucketJobseeker}/Offerhead.svg`
export const SmallAppLogo = `${S3BucketJobseeker}/SmallAppLogo.png`

// Chat
export const Offerbackground = `${S3BucketJobseeker}/Offerbackground.png`
export const HomePageChat = `${S3BucketJobseeker}/HomePageChat.svg`
export const Search = `${S3BucketJobseeker}/Search.svg`
export const ChatCircleDots = `${S3BucketJobseeker}/chat-circle-dots.svg`
export const Chrome = `${S3BucketJobseeker}/chrome.png`
export const Safari = `${S3BucketJobseeker}/safari.png`
export const OtherBrowser = `${S3BucketJobseeker}/other-browser.png`
export const BriefcaseIcon = `${S3BucketJobseeker}/BriefcaseIcon.svg`
export const CommissionIcon = `${S3BucketJobseeker}/CommissionIcon.svg`
export const DropdownArrowIcon = `${S3BucketJobseeker}/DropdownArrowIcon.svg`
export const EducationIcon = `${S3BucketJobseeker}/EducationIcon.svg`
export const EmployeeStockPurchaseIcon = `${S3BucketJobseeker}/EmployeeStockPurchaseIcon.svg`
export const EquityIncentiveIcon = `${S3BucketJobseeker}/EquityIncentiveIcon.svg`
export const HousingAllowanceIcon = `${S3BucketJobseeker}/HousingAllowanceIcon.svg`
export const LocationIcon = `${S3BucketJobseeker}/LocationIcon.svg`
export const MealAllowanceIcon = `${S3BucketJobseeker}/MealAllowanceIcon.svg`
export const NotificationIcon = `${S3BucketJobseeker}/NotificationIcon.svg`
export const BellIcon = `${S3BucketJobseeker}/BellIcon.svg`
export const OtherAllowancesIcon = `${S3BucketJobseeker}/OtherAllowancesIcon.svg`
export const CarIcon = `${S3BucketJobseeker}/Car.svg`
export const PerformanceBonusIcon = `${S3BucketJobseeker}/PerformanceBonusIcon.svg`
export const SalaryIcon = `${S3BucketJobseeker}/SalaryIcon.svg`
export const TelecommunicationAllowanceIcon = `${S3BucketJobseeker}/TelecommunicationAllowanceIcon.svg`
export const TransportAllowanceIcon = `${S3BucketJobseeker}/TransportAllowanceIcon.svg`
export const CompanyIcon = `${S3BucketJobseeker}/CompanyIcon.svg`
export const MoreIcon = `${S3BucketJobseeker}/MoreIcon.svg`
export const CloseIcon = `${S3BucketJobseeker}/CloseIcon.svg`
export const Plus = `${S3BucketJobseeker}/plus.svg`
export const Minus = `${S3BucketJobseeker}/minus.svg`
export const ChevronUpIcon = `${S3BucketJobseeker}/ChevronUp.svg`
export const ChevronDownIcon = `${S3BucketJobseeker}/ChevronDownIcon.svg`
export const CreateIcon = `${S3BucketJobseeker}/CreateIcon.svg`
export const DeleteIcon = `${S3BucketJobseeker}/DeleteIcon.svg`
export const FacebookIcon = `${S3BucketJobseeker}/FacebookIcon.svg`
export const TwitterIcon = `${S3BucketJobseeker}/TwitterIcon.svg`
export const LinkedinIcon = `${S3BucketJobseeker}/LinkedinIcon.svg`
export const AppleIcon = `${S3BucketJobseeker}/loginAppleIcon.svg`
export const EmailIcon = `${S3BucketJobseeker}/EmailIcon.svg`
export const PhoneIcon = `${S3BucketJobseeker}/PhoneIcon.svg`

// Copy
export const CopyIcon = `${S3BucketJobseeker}/CopyIcon.svg`
export const CopyBlueIcon = `${S3BucketJobseeker}/CopyBlue.svg`
export const CopyIconHaveTextCopy = `${S3BucketJobseeker}/CopyIconHaveTextCopy.svg`

export const ArrowForwardIcon = `${S3BucketJobseeker}/ArrowForwardIcon.svg`
export const FacebookIconGrey = `${S3BucketJobseeker}/FacebookIconGrey.svg`
export const TwitterIconGrey = `${S3BucketJobseeker}/TwitterIconGrey.svg`
export const InstagramIconGrey = `${S3BucketJobseeker}/InstagramIconGrey.svg`
export const ChatIcon = `${S3BucketJobseeker}/ChatIcon.svg`
export const DefaultAvatar = `${S3BucketJobseeker}/DefaultJobseekerAvatar.png`
export const DownloadOnAppStore = `${S3BucketJobseeker}/DownloadOnAppStore.svg`
export const DownloadOnGooglePlay = `${S3BucketJobseeker}/DownloadOnGooglePlay.svg`
export const FilterIcon = `${S3BucketJobseeker}/FilterIcon.svg`
export const ExpireIcon = `${S3BucketJobseeker}/ExpireIcon.svg`
export const VectorBulletedListIcon = `${S3BucketJobseeker}/VectorBulletedListIcon.svg`
export const VectorNumberedListIcon = `${S3BucketJobseeker}/VectorNumberedListIcon.svg`
export const InfoIcon = `${S3BucketJobseeker}/InfoIcon.svg`
export const CreateFilledIcon = `${S3BucketJobseeker}/CreateFilledIcon.svg`
export const DeleteFilledIcon = `${S3BucketJobseeker}/DeleteFilledIcon.svg`
export const AddOutlineIcon = `${S3BucketJobseeker}/AddOutlineIcon.svg`
export const ResumeTemplatePreview = `${S3BucketJobseeker}/ResumeTemplatePreview.png`
export const ResumeTemplate1 = `${S3BucketJobseeker}/ResumeTemplate1.png`
export const ResumeTemplate2 = `${S3BucketJobseeker}/ResumeTemplate2.png`
export const LocationPinIcon = `${S3BucketJobseeker}/LocationPinIcon.svg`
export const RateIcon = `${S3BucketJobseeker}/RateIcon.svg`
export const TrashIcon = `${S3BucketJobseeker}/trash.svg`
export const TrashIcon2 = `${S3BucketJobseeker}/trash_s2.svg`

export const DocumentIcon = `${S3BucketJobseeker}/document.svg`
export const DisclaimerIcon = `${S3BucketJobseeker}/disclaimer.svg`
export const MailIcon = `${S3BucketJobseeker}/mailIcon.svg`
export const PencilIcon = `${S3BucketJobseeker}/pencil.svg`
export const MobileIcon = `${S3BucketJobseeker}/mobile.svg`
export const ProfileIcon = `${S3BucketJobseeker}/profile.svg`
export const CameraIcon = `${S3BucketJobseeker}/CameraIcon.svg`
export const ApplySuccess = `${S3BucketJobseeker}/ApplySuccess.png`
export const AddIcon = `${S3BucketJobseeker}/add.svg`
export const BodyIcon = `${S3BucketJobseeker}/BodyIcon.svg`
export const DownloadWhiteIcon = `${S3BucketJobseeker}/DownloadWhite.svg`
export const CarouselRightRoundedBlueButton = `${S3BucketJobseeker}/CarouselRightRoundedBlueButton.svg`
export const BlueTickIcon = `${S3BucketJobseeker}/BlueTick.svg`
export const OpenInNewTabIcon = `${S3BucketJobseeker}/OpenNewTab.svg`
export const increaseUserConversionModelBg = `${S3BucketJobseeker}/increaseUserConversionModelBg.png`
export const ncreaseUserConversionDetailsBackgroundarrowArrow = `${S3BucketJobseeker}/ncreaseUserConversionDetailsBackgroundarrowArrow.svg`
export const AccountSettingDeleteIconBin = `${S3BucketJobseeker}/accountSettingDeleteIconBin.svg`
export const AccountSettingEditIconPen = `${S3BucketJobseeker}/accountSettingEditIconPen.svg`
export const increaseUserConversionBrush = `${S3BucketJobseeker}/increaseUserConversionBrush.svg`
export const TooltipIcon = `${S3BucketJobseeker}/TooltipIcon.svg`

/* Manage profile highlight section */
export const HighlightAboutYouIcon = `${S3BucketJobseeker}/HighlightAboutYou.svg`
export const HighlightSkillIcon = `${S3BucketJobseeker}/HighlightSkill.svg`
export const HighlightEducationIcon = `${S3BucketJobseeker}/HighlightEducation.svg`
export const HighlightWorkExpIcon = `${S3BucketJobseeker}/HighlightWorkExp.svg`

/* Footer Social Links Logo */
export const facebook = `${S3BucketJobseeker}/facebook.svg`
export const instagram = `${S3BucketJobseeker}/instagram.svg`
export const twitter = `${S3BucketJobseeker}/twitter.svg`
export const linkedin = `${S3BucketJobseeker}/linkedin.svg`
export const youtube = `${S3BucketJobseeker}/youtube.svg`
export const GoogleLogo = `${S3BucketJobseeker}/google-logo.png`
export const LinkedinLogo = `${S3BucketJobseeker}/linkedin-logo.png`
export const FacebookLogo = `${S3BucketJobseeker}/facebook-logo.png`
export const tiktok = `${S3BucketJobseeker}/tiktok.svg`
export const quickCreateResume = `${S3BucketJobseeker}/increase-user-conversion-details_background-2.png`

/* Landing Page Images */
export const BuildProfessionalResume = `${S3BucketJobseeker}/BuildProfessionalResume.png`
export const ChatDirectlyWithBoss = `${S3BucketJobseeker}/ChatDirectlyWithBoss.png`
export const GetHeadhunted = `${S3BucketJobseeker}/GetHeadhunted.png`
export const LevelUpCareer = `${S3BucketJobseeker}/LevelUpCareer.png`
export const BusinessInsider = `${S3BucketJobseeker}/BusinessInsider.svg`
export const GrabVentures = `${S3BucketJobseeker}/GrabVentures.svg`
export const KrAsia = `${S3BucketJobseeker}/KrAsia.svg`
export const MoneyMax = `${S3BucketJobseeker}/MoneyMax.svg`
export const TechInAsia = `${S3BucketJobseeker}/TechInAsia.svg`
export const RHBannerDesktop = `${S3BucketJobseeker}/rh_banner_desktop.png`
export const RHBannerMobile = `${S3BucketJobseeker}/rh_banner_mobile.png`
export const RHBannerTablet = `${S3BucketJobseeker}/rh_banner_tablet.png`

/* Company Detail Social Link */
export const FacebookOutline = `${S3BucketJobseeker}/facebook_round.svg`
export const YoutubeOutline = `${S3BucketJobseeker}/youtube_round.svg`
export const InstagramOutline = `${S3BucketJobseeker}/instagram_round.svg`
export const LinkedinOutline = `${S3BucketJobseeker}/linkedin_round.svg`

/* Company Life Icons */
export const CareerGrowth = `${S3BucketJobseeker}/CareerGrowth.svg`
export const DailyRoutine = `${S3BucketJobseeker}/DailyRoutine.svg`
export const PersonalHealth = `${S3BucketJobseeker}/PersonalHealth.svg`
export const TeamCollaboration = `${S3BucketJobseeker}/TeamCollaboration.svg`
export const Strategy = `${S3BucketJobseeker}/Strategy.svg`
export const Insurance = `${S3BucketJobseeker}/Insurance.svg`
export const PerksAndBenefits = `${S3BucketJobseeker}/PerksAndBenefits.svg`
export const Leaves = `${S3BucketJobseeker}/Leaves.svg`
export const CompanyVerifiedIcon = `${S3BucketJobseeker}/company_verified_icon.svg`

/* 404 page */
export const NotFound404 = `${S3BucketJobseeker}/404.png`

/* Maintenance page */
export const Maintenance = `${S3BucketJobseeker}/maintenance.png`

/* Resume preview */
export const CreativeResumePreview = `${S3BucketJobseeker}/creative_resume_preview.pdf`
export const CorporateResumePreview = `${S3BucketJobseeker}/corporate_resume_preview.pdf`

/* App Down */
export const AppDownQRCode = `${S3BucketJobseeker}/AppDownQRCode.png`

export const JoinUs = `${S3BucketJobseeker}/Joinus.png`
/* Share */
export const ShareIcon = `${S3BucketJobseeker}/ShareIcon.svg`

/* ReportIcon */
export const ReportIcon = `${S3BucketJobseeker}/ReportIcon.svg`

/* UploadResumeIcon.svg */
export const UploadResumeIcon = `${S3BucketJobseeker}/UploadResumeIcon.svg`

/* CompleteResumeIcon.svg */
export const CompleteResumeIcon = `${S3BucketJobseeker}/CompleteResumeIcon.svg`

export const LoginSafe = `${S3BucketJobseeker}/loginSafa.gif`
export const RightArrowIcon = `${S3BucketJobseeker}/right_arrow_icon.svg`
export const HistoryIcons = `${S3BucketJobseeker}/HistoryIcons.svg`
export const SgIdNotMatch = `${S3BucketJobseeker}/sgIdNotMatch.svg`
/** Footer icon */
export const footer_googleplay_download = `${S3BucketJobseeker}/footer_googleplay_download.svg`
export const footer_apple_download = `${S3BucketJobseeker}/footer_apple_download.svg`
export const footer_twitter_icon = `${S3BucketJobseeker}/footer_twitter_icon.svg`
export const footer_youtube_icon = `${S3BucketJobseeker}/footer_youtube_icon.svg`
export const footer_tiktok_icon = `${S3BucketJobseeker}/footer_tiktok_icon.svg`
export const footer_instagram_icon = `${S3BucketJobseeker}/footer_instagram_icon.svg`
export const footer_linkedIn_icon = `${S3BucketJobseeker}/footer_linkedIn_icon.svg`
export const footer_facebook_icon = `${S3BucketJobseeker}/footer_facebook_icon.svg`
export const footer_logo = `${S3BucketJobseeker}/footer_logo.svg`
export const footer_twitterX_icon = `${S3BucketJobseeker}/footer_twitterX_icon.svg`

// navbar icons
export const navbar_location_icon = `${S3BucketJobseeker}/navbar_location_icon.svg`
export const scanHunt = `${S3BucketJobseeker}/scanHunt.png`
export const scanJob = `${S3BucketJobseeker}/scanJob.png`

// find job
export const UploadDocIcon = `${S3BucketJobseeker}/UploadDocIcon.svg`
export const JobSearchIcon = `${S3BucketJobseeker}/jobSearchIcon.svg`
export const ClearIcon = `${S3BucketJobseeker}/ClearIcon.svg`
export const MoreFilterIcon = `${S3BucketJobseeker}/MoreFilterIcon.svg`

// home
export const BannerBackground = `${S3BucketJobseeker}/banner.svg`

// empty
export const Empty = `${S3BucketJobseeker}/empty.svg`

export const seekerBanner = `${S3BucketJobseeker}/seekerBanner.png`

// footer icon
export const FooterGalaxyAppStore = `${S3BucketJobseeker}/FooterGalaxyAppstore.png`
export const FooterHuaweiAppStore = `${S3BucketJobseeker}/FooterHuaweiAppstore.png`
export const FooterXiaomiAppStore = `${S3BucketJobseeker}/FooterXiaomiApp store.png`
export const delVideoResume = `${S3BucketJobseeker}/icon_delvideo.png`
export const playVideoResume = `${S3BucketJobseeker}/icon_playvideo.png`

// recruiter banners
export const recruiterBanner1 = `${S3BucketJobseeker}/bg-hero-01.jpg`
export const recruiterBanner2 = `${S3BucketJobseeker}/bg-hero-02.jpg`
export const recruiterBanner3 = `${S3BucketJobseeker}/bg-hero-03.jpg`
export const recruiterBanner4 = `${S3BucketJobseeker}/bg-hero-04.jpg`
export const recruiterBanner5 = `${S3BucketJobseeker}/bg-hero-05.jpg`

export const remoteJobsBanner = `${S3BucketJobseeker}/remoteJob.png`

export const remoteHomeBanner = `${S3BucketJobseeker}/remoteHomeBanner.png`
export const remoteHomeBannerH5 = `${S3BucketJobseeker}/remoteHomeBannerH5.png`

export const jobUploadBackground = `${S3BucketJobseeker}/uploadBackground.png`

export const personalSettingBg1 = `${S3BucketJobseeker}/bg-hero-01.jpg`
export const personalSettingBg2 = `${S3BucketJobseeker}/bg-hero-02.jpg`
export const personalSettingBg3 = `${S3BucketJobseeker}/bg-hero-03.jpg`
export const personalSettingBg4 = `${S3BucketJobseeker}/bg-hero-04.jpg`



// talent
export const talentTag = `${S3BucketJobseeker}/talent2/tag.png`
export const talentBlock = `${S3BucketJobseeker}/talent2/block.png`
export const talentEarth = `${S3BucketJobseeker}/talent2/earth.png`
export const talentPeople = `${S3BucketJobseeker}/talent2/people.png`
export const talent01 = `${S3BucketJobseeker}/talent2/01.png`
export const talent02 = `${S3BucketJobseeker}/talent2/02.png`
export const talent03 = `${S3BucketJobseeker}/talent2/03.png`
export const talentFeature01 = `${S3BucketJobseeker}/talent2/feature01.png`
export const talentFeature02 = `${S3BucketJobseeker}/talent2/feature02.png`
export const talentFeature03 = `${S3BucketJobseeker}/talent2/feature03.png`
export const talentHome = `${S3BucketJobseeker}/talent2/home.png`
export const talentOccupation01 = `${S3BucketJobseeker}/talent2/occupation01.png`
export const talentOccupation02 = `${S3BucketJobseeker}/talent2/occupation02.png`
export const talentOccupation03 = `${S3BucketJobseeker}/talent2/occupation03.png`
export const talentOccupation04 = `${S3BucketJobseeker}/talent2/occupation04.png`
export const talentOccupation05 = `${S3BucketJobseeker}/talent2/occupation05.png`
export const talentOccupation06 = `${S3BucketJobseeker}/talent2/occupation06.png`
export const talentOccupation07 = `${S3BucketJobseeker}/talent2/occupation07.png`
export const talentOccupation08 = `${S3BucketJobseeker}/talent2/occupation08.png`
export const talentOccupation09 = `${S3BucketJobseeker}/talent2/occupation09.png`
export const talentHeaderBg = `${S3BucketJobseeker}/talent2/headerBg.png`
export const talentFrame01 = `${S3BucketJobseeker}/talent2/frame01.png`
export const talentFrame02 = `${S3BucketJobseeker}/talent2/frame02.png`
export const talentFrame03 = `${S3BucketJobseeker}/talent2/frame03.png`
export const talentArow01 = `${S3BucketJobseeker}/talent2/arow01.png`
export const talentArrow02 = `${S3BucketJobseeker}/talent2/arrow02.png`
export const talentCountry = `${S3BucketJobseeker}/talent2/country.png`
export const talentApp = `${S3BucketJobseeker}/talent2/app.png`
export const talentCenter = `${S3BucketJobseeker}/talent2/center.png`
export const talentLogin = `${S3BucketJobseeker}/talent2/login.png`
export const talentPreference = `${S3BucketJobseeker}/talent2/preference.png`
export const talentMask = `${S3BucketJobseeker}/talent2/mask.png`
export const talentMask02 = `${S3BucketJobseeker}/talent2/mask02.png`
export const talentQa = `${S3BucketJobseeker}/talent2/qa.png`
export const talentMobileBg = `${S3BucketJobseeker}/talent2/mobileBg.png`
export const talentMobile01 = `${S3BucketJobseeker}/talent2/mobile01.png`
export const talentMobile02 = `${S3BucketJobseeker}/talent2/mobile02.png`
export const talentMobile03 = `${S3BucketJobseeker}/talent2/mobile03.png`
export const talentMobile04 = `${S3BucketJobseeker}/talent2/mobile04.png`
export const talentMobile05 = `${S3BucketJobseeker}/talent2/mobile05.png`
export const talentJobBg = `${S3BucketJobseeker}/talent2/jobBg.png`

