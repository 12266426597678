import configuredAxios from 'helpers/configuredAxios'
import { getCookie } from 'helpers/cookies'

const traceDistination = (params) => {
  const userCookie = getCookie('user')
  const axios = configuredAxios('auth', '')
  if (!params) return
  if (params?.tracker_name) {
    params.trackerName = params.tracker_name
    delete params.tracker_name
  }
  if (params?.tracker_token) {
    params.trackerToken = params.tracker_token
    delete params.tracker_token
  }
  params['user_id'] = params['user_id'] || userCookie?.id || ''
  return axios.post(`/user/destination/traceability`, params)
}

export { traceDistination }